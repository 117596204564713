// extracted by mini-css-extract-plugin
export var explorecontent = "index-module--explorecontent--2e569";
export var exploredescriptioncontent = "index-module--exploredescriptioncontent--1fc52";
export var exploredescriptionholder = "index-module--exploredescriptionholder--06b65";
export var exploredescriptionplaylist = "index-module--exploredescriptionplaylist--5a4b8";
export var exploredescriptiontitle = "index-module--exploredescriptiontitle--6e447";
export var exploreheading = "index-module--exploreheading--6cb13";
export var exploreitemrow = "index-module--exploreitemrow--99831";
export var explorethumnailcorner = "index-module--explorethumnailcorner--3322e";
export var explorethumnailgap = "index-module--explorethumnailgap--efd8e";
export var explorethumnailholder = "index-module--explorethumnailholder--aa37e";
export var explorethumnailimage = "index-module--explorethumnailimage--fa59d";
export var heading = "index-module--heading--657e0";
export var headingbannermore = "index-module--headingbannermore--76a94";
export var headingholder = "index-module--headingholder--6cf60";
export var headingstatistics = "index-module--headingstatistics--a28a8";
export var headingstatisticsitem = "index-module--headingstatisticsitem--202b5";
export var headingtext = "index-module--headingtext--47c39";
export var headingvideo = "index-module--headingvideo--21b88";
export var homecontent = "index-module--homecontent--e4691";
export var recentarrowback = "index-module--recentarrowback--4de12";
export var recentarrownext = "index-module--recentarrownext--0ad5e";
export var recenttitle = "index-module--recenttitle--85680";
export var recentvideothumbnail = "index-module--recentvideothumbnail--52fa2";