import React from "react"
import PropTypes from "prop-types"
import * as styles from "./style.module.css"


const ContentDivider = ({ className }) => {
  return <></>
  /*
  return (
    <div className={styles.contentdivider+" "+className+" color-text-highlight-green font-size-huge"}>&#9670;</div>
  )
  */

}
ContentDivider.propTypes = {
  className: PropTypes.string
}

ContentDivider.defaultProps = {
  className:``
}

export default ContentDivider
