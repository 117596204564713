import React from "react"
import PropTypes from "prop-types"
import * as styles from "./style.module.css"


import videobg from "../../images/videoholder.png"


const VideoHolder = ({ className, videotype, videoid, videotitle, roundedCorner, description, mode}) => {
  var srcurl = "";
  if (videotype === "youtube") {
    srcurl = "https://www.youtube.com/embed/?rel=0"+videoid;
    if (mode === "embed") {
      srcurl = srcurl + "&playlist="+videoid+"&controls=0&disablekb=1&autoplay=0&loop=1";
    }
  }

  return (
  	<div itemscope="" itemprop="VideoObject" itemtype="https://schema.org/VideoObject" className={styles.videoholder+" "+className}>
        {videotitle !== "" &&
          <meta itemprop="name" content={videotitle} />
        }
        {description !== "" &&
          <meta itemprop="description" content={description} />
        }
        <meta itemprop="uploadDate" content={"2022-01-01T08:00:00+08:00"} />
        {videotype === "youtube" && <>
          <meta itemprop="embedUrl" content={"https://www.youtube.com/embed/"+videoid} />
          <meta itemprop="thumbnailUrl" content={"https://img.youtube.com/vi/"+videoid+"/maxresdefault.jpg"} />
        </>}
        <img className={styles.videoholder} src={videobg} alt="video"/>
        <iframe frameBorder="0" title={videotitle} className={roundedCorner === false?styles.videoholder:styles.videoholder+" "+styles.videoholderrounded} width="100%" height="100%" src={srcurl}></iframe>
  	</div>
  )

}
VideoHolder.propTypes = {
  description: PropTypes.string,
  className: PropTypes.string,
  videotitle: PropTypes.string,
  videotype: PropTypes.string,
  roundedCorner: PropTypes.bool,
  mode: PropTypes.string
}

VideoHolder.defaultProps = {
  className:``,
  description:``,
  roundedCorner: false,
  videotitle: ``,
  videotype: `youtube`,
  mode: ""
}

export default VideoHolder
